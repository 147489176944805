import { FC, HTMLAttributes } from 'react'
import clsx from 'clsx'

export interface LabelProps extends HTMLAttributes<HTMLLabelElement> {
  readonly name?: string
  readonly htmlFor?: string
}

const Label: FC<LabelProps> = ({ name, htmlFor, children, className, ...props }) => {
  return (
    <label
      className={clsx('text-deep-teal-900 block text-sm font-medium leading-5', className)}
      htmlFor={htmlFor || name}
      {...props}
    >
      {children}
    </label>
  )
}

export default Label
