import { ComponentPropsWithoutRef } from 'react'
import { Link } from 'ui/components/navigation'
import { cn } from 'ui/lib'

export function InfoCardPipelineInvestmentsDesktop({
  className,
  ...props
}: ComponentPropsWithoutRef<'div'>) {
  return (
    <Link
      className={cn(
        'bg-deep-gray-50 flex flex-col gap-2 rounded-lg p-4 shadow transition-shadow hover:shadow-xl',
        className,
      )}
      href="/articles/pipeline-faq"
      target="_blank"
      {...props}
    >
      <div className="flex h-full w-full flex-col items-center justify-end overflow-hidden">
        <TitleImage className="shrink-1" />
      </div>
      <p className="font-brand text-deep-teal-500 text-2xl font-medium">Read our Pipeline FAQ</p>
      <p className="text-deep-teal-400 text-xs font-normal">
        The Pipeline deals and deal themes are currently in early stages of diligence by our team
        and the Field Experts. Submit an IOI to help us gauge demand for these opportunities.
      </p>
    </Link>
  )
}

function TitleImage(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="286"
      height="303"
      viewBox="0 0 286 303"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="33.3191"
        y="62.1653"
        width="43.3931"
        height="272.635"
        transform="rotate(-42.2309 33.3191 62.1653)"
        fill="#E0E1E3"
      />
      <rect
        x="1"
        y="64.152"
        width="95.4473"
        height="31.5275"
        rx="3.05458"
        transform="rotate(-42.2309 1 64.152)"
        fill="#E0E1E3"
      />
      <rect
        x="193"
        y="276.152"
        width="95.4473"
        height="31.5275"
        rx="3.05458"
        transform="rotate(-42.2309 193 276.152)"
        fill="#E0E1E3"
      />
      <circle cx="139.352" cy="148.267" r="80.5645" stroke="#E0E1E3" strokeWidth="24" />
      <path
        d="M141.137 199.665C169.891 198.264 192.066 173.819 190.665 145.064C189.264 116.31 164.819 94.1356 136.064 95.5363C107.31 96.9371 85.1355 121.383 86.5363 150.137C87.9371 178.891 112.383 201.066 141.137 199.665Z"
        fill="#0A373C"
      />
      <path
        d="M134.133 133.644C138.182 133.446 140.514 135.761 140.73 140.187L141.458 155.132L138.744 155.265L138.044 140.903C137.914 138.231 137.172 135.882 133.665 136.053C129.907 136.236 127.835 139.223 127.986 142.314L128.641 155.757L125.927 155.889L125.227 141.527C125.097 138.856 124.354 136.507 120.806 136.679C117.091 136.86 114.976 139.85 115.127 142.94L115.782 156.383L113.068 156.515L112.03 135.223L114.744 135.091L114.891 138.096L114.974 138.092C116.018 136.326 117.934 134.433 121.275 134.27C124.28 134.124 126.268 135.408 127.185 137.916L127.269 137.912C128.543 135.716 130.752 133.808 134.133 133.644ZM155.749 132.466C161.177 132.201 165.517 136.259 165.859 143.273C166.201 150.287 162.273 154.705 156.846 154.969C153.506 155.132 151.386 153.729 150.134 152.073L150.051 152.077L150.539 162.097L146.447 162.296L145.05 133.614L149.141 133.415L149.263 135.921L149.347 135.917C150.442 134.357 152.41 132.628 155.749 132.466ZM156.012 151.619C160.021 151.424 161.762 147.656 161.559 143.482C161.356 139.307 159.256 135.726 155.248 135.922C151.574 136.101 149.465 139.216 149.701 144.06C149.935 148.86 152.338 151.798 156.012 151.619Z"
        fill="white"
      />
    </svg>
  )
}
