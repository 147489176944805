import { FC } from 'react'
import { Feed } from 'amity/components'
import { withErrorBoundaryAndLogging } from 'ui/components/content'
import { useRoutingContext } from 'ui/lib/navigation'

const AmityFeedImplementation: FC = () => {
  const { baseUrl, push } = useRoutingContext()

  return (
    <section className="gap-4 xl:col-span-2 xl:col-start-2 xl:row-start-2">
      <Feed router={{ push }} baseUrl={baseUrl} targetType="global" />
    </section>
  )
}

export const AmityFeed = withErrorBoundaryAndLogging(AmityFeedImplementation)
