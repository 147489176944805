import { FC, HTMLAttributes } from 'react'
import { GeolocationContext, GeolocationService } from './GeolocationContext'

interface GeolocationContextProviderProps extends Pick<HTMLAttributes<'div'>, 'children'> {
  readonly value: GeolocationService
}

export const GeolocationContextProvider: FC<GeolocationContextProviderProps> = ({
  children,
  value,
}) => {
  return <GeolocationContext.Provider value={value}>{children}</GeolocationContext.Provider>
}
