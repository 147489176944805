import { AmityUiKitProvider, AmityUiKitFeed } from '@amityco/ui-kit-open-source'
import { axios } from 'api/lib'
import { useSession } from 'auth'

export type MinimalRouter = { push: (url: string) => void }

export type FeedProps = {
  showPostCreator?: boolean
  targetType?: string
  targetId?: string
  baseUrl: string
  router: MinimalRouter
}

export function Feed({ baseUrl, showPostCreator, targetType, targetId, router }: FeedProps) {
  const { user } = useSession()
  const displayName = `${user.firstName} ${user.lastName}`

  return (
    <AmityUiKitProvider
      apiKey={process.env.NEXT_PUBLIC_AMITY_API_KEY}
      apiRegion="us"
      userId={user.id.toString()}
      displayName={displayName}
      getAuthToken={getAuthToken}
      actionHandlers={{
        onClickUser: (id: string) => navigateToMemberProfile(id, router, baseUrl),
        onClickCommunity: (id: string) => handleCommunityClick(id, router),
      }}
    >
      <AmityUiKitFeed
        showPostCreator={showPostCreator}
        targetType={targetType}
        targetId={targetId}
      />
    </AmityUiKitProvider>
  )
}

export async function getAuthToken(): Promise<string> {
  const { data } = await axios.get<string>('/api/amity/token')
  return data
}

export async function navigateToMemberProfile(
  id: string,
  router: MinimalRouter,
  baseUrl: string,
): Promise<void> {
  if (Number.isInteger(Number(id))) {
    router.push(`${baseUrl}/members/${id}`)
  }
}

export async function handleCommunityClick(groupId: string, router: MinimalRouter): Promise<void> {
  const { data: ioId } = await axios.get<number>(`/api/investment-opportunities/groupId/${groupId}`)

  if (ioId) {
    router.push(`/investments/${ioId}`)
  } else {
    router.push(`/groups/${groupId}`)
  }
}
