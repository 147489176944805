import { useEffect, useMemo, useState } from 'react'
import { MembersDiscoveryLocation } from 'api/dto'
import { debounce } from 'lodash'
import { useGeocoding } from './useGeocoding'

const MIN_QUERY_LENGTH = 3
const RESULTS_LIMIT = 3

export const useLocationsSearch = (myLocation?: [number, number]) => {
  const [query, setQuery] = useState('')
  const [error, setError] = useState<Error | undefined>(undefined)
  const [locations, setLocations] = useState<MembersDiscoveryLocation[]>([])
  const { geocoding } = useGeocoding()

  const onQueryChangeHandler = (value: string) => setQuery(value)

  const onQueryChangeDebouncedHandler = useMemo(() => debounce(onQueryChangeHandler, 500), [])

  useEffect(() => {
    if (!geocoding || query.length < MIN_QUERY_LENGTH) {
      return setLocations([])
    }

    // Typing error in library
    const proximity = myLocation || ('ip' as any)

    geocoding
      .forwardGeocode({
        query,
        types: ['place', 'region', 'country'],
        language: ['en'],
        limit: RESULTS_LIMIT,
        proximity,
      })
      .then((locations) => setLocations(locations))
      .catch((error) => setError(error))
  }, [query, myLocation && `${myLocation[0]},${myLocation[1]}`, geocoding])

  return { onQueryChangeDebouncedHandler, locations, error }
}
