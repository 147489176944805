import { useEffect, useState } from 'react'
import { MapiResponse } from '@mapbox/mapbox-sdk/lib/classes/mapi-response'
import {
  GeocodeFeature,
  GeocodeRequest,
  GeocodeResponse,
} from '@mapbox/mapbox-sdk/services/geocoding'
import { MembersDiscoveryLocation } from 'api/dto'
import { geocoding } from 'ui/src/lib/mapbox'

type ForwardGeocode = (request: GeocodeRequest) => Promise<MembersDiscoveryLocation[]>
type ReverseGeocode = (request: GeocodeRequest) => Promise<MembersDiscoveryLocation[]>
interface GeocodingService {
  forwardGeocode: ForwardGeocode
  reverseGeocode: ReverseGeocode
}

export const useGeocoding = () => {
  const [geocodingService, setGeocodingService] = useState<GeocodingService | undefined>(undefined)
  const [error, setError] = useState()

  const createGeocodeService = () => {
    const geocodingService = geocoding()
    const catchGeocodeServiceError = (error: any) => {
      setError(error)
      return []
    }
    const forwardGeocode = async (request: GeocodeRequest) => {
      setError(undefined)
      return geocodingService
        .forwardGeocode(request)
        .send()
        .then(mapGeocodeResponseToLocations)
        .catch(catchGeocodeServiceError)
    }
    const reverseGeocode = async (request: GeocodeRequest) => {
      setError(undefined)
      return geocodingService
        .reverseGeocode(request)
        .send()
        .then(mapGeocodeResponseToLocations)
        .catch(catchGeocodeServiceError)
    }
    setGeocodingService({
      forwardGeocode,
      reverseGeocode,
    } as GeocodingService)
  }

  useEffect(() => {
    try {
      createGeocodeService()
    } catch (error: any) {
      setError(error)
    }
  }, [])

  return { geocoding: geocodingService, error }
}

const mapGeocodeResponseToLocations = ({ body: response }: MapiResponse<GeocodeResponse>) =>
  response.features.map((feature) => {
    const [placeType] = feature.place_type
    switch (placeType) {
      case 'country':
        return {
          country: getFeatureName(feature),
        }

      case 'region':
        return {
          region: getFeatureName(feature),
          country: getContextFeatureName(feature, 'country'),
        }

      case 'place':
        return {
          lng: feature.center[0],
          lat: feature.center[1],
          city: getFeatureName(feature),
          region: getContextFeatureName(feature, 'region'),
          country: getContextFeatureName(feature, 'country'),
        }
    }
  })

const getFeatureName = (feature: GeocodeFeature): string => feature.text

const getContextFeatureName = (
  feature: GeocodeFeature,
  contextPlaceType: string,
): string | undefined => {
  if (!feature.context?.length) return undefined
  const contextFeature = feature.context.find((feature) => {
    const [placeType] = feature.id.split('.')
    return placeType === contextPlaceType
  })
  return contextFeature?.text
}
