import { FC } from 'react'
import { cn } from 'ui/lib'

interface FilterSectionProps {
  title: string
  children: React.ReactNode
  className?: string
}

export const FilterSection: FC<FilterSectionProps> = ({ title, children, className }) => (
  <div className={cn('flex flex-col gap-4', className)}>
    <h3 className="text-deep-gray-600 text-sm font-medium">{title}</h3>
    <div className="bg-deep-teal-100 h-px" />
    {children}
  </div>
)
