import { FC } from 'react'
import Link from 'next/link'
import { MapIcon } from '@heroicons/react/20/solid'
import { UserSocialProfile } from 'api/dto'
import { useFlag, LDFlag } from 'launchdarkly'
import { AmityAvatar } from 'ui/components/content'
import { ProfileNominatedBy } from './ProfileNominatedBy'
import { ProfileTemplate } from './ProfileTemplate'

export const Profile: FC<{ profile: UserSocialProfile }> = ({ profile }) => {
  const enableMembersDiscoveryLink = useFlag(LDFlag.EnableMemberDiscoveryNavigation)
  return (
    <ProfileTemplate
      avatar={<AmityAvatar className="h-14 w-14" fileId={profile.avatarFileId} />}
      firstName={profile.firstName}
      lastName={profile.lastName}
      isFoundingMember={profile.isFoundingMember}
    >
      <div className="flex flex-col gap-4">
        {profile.nominatedBy.length > 0 && (
          <>
            <ProfileNominatedBy nominatedBy={profile.nominatedBy} />
            <div className="bg-deep-teal-100 h-px last-of-type:hidden" />
          </>
        )}

        {enableMembersDiscoveryLink && (
          <>
            <Link
              className={'leading-1 flex items-center font-semibold text-orange-600'}
              href="/member-directory"
            >
              <span className="mr-2">Discover meetperry members</span>
              <MapIcon className="h-4 w-4" />
            </Link>
            <div className="bg-deep-teal-100 h-px last-of-type:hidden" />
          </>
        )}
      </div>
    </ProfileTemplate>
  )
}
