import { ComponentPropsWithoutRef } from 'react'
import { Link } from 'ui/components/navigation'
import { cn } from 'ui/lib'

export function InfoCardOffPisteInvestmentsDesktop({
  className,
  ...props
}: ComponentPropsWithoutRef<'div'>) {
  return (
    <Link
      className={cn(
        'bg-deep-gray-50 flex flex-col justify-end gap-2 rounded-lg p-4 shadow transition-shadow hover:shadow-xl',
        className,
      )}
      href="/articles/off-piste-faq"
      target="_blank"
      {...props}
    >
      <div className="flex h-full w-full items-end justify-center overflow-hidden">
        <TitleImage className="max-h-full max-w-full" />
      </div>
      <p className="font-brand text-deep-teal-500 text-2xl font-medium">Read our Off-Piste FAQ</p>
      <p className="text-deep-teal-400 text-xs font-normal">
        Off-Piste deals have been submitted by members directly and have not been reviewed by
        meetperry. Submit an IOI to help us determine which deals to allocate Due Diligence
        resources to.
      </p>
    </Link>
  )
}

function TitleImage(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0.005 1.522 280.592 243.021"
      width="280.592px"
      height="243.021px"
      {...props}
    >
      <path
        d="M 140.301 1.522 L 280.597 244.522 L 0.005 244.522 L 140.301 1.522 Z"
        fill="#D9D9D9"
      />
      <path
        d="M 136.764 34.787 C 130.906 29.084 111.135 51.419 112.355 49.043 L 139.205 1.522 L 170.937 56.171 C 168.496 58.943 158.244 71.853 146.528 82.308 C 131.882 95.376 144.087 41.915 136.764 34.787 Z"
        fill="white"
        stroke="white"
        strokeWidth="2.34286"
      />
      <path
        d="M 192.111 167.525 L 236.443 244.31 L 147.78 244.31 L 192.111 167.525 Z"
        fill="#BCBCBC"
      />
      <path
        d="M 189.484 184.992 C 186.845 182.353 177.914 192.306 178.464 191.207 L 192.234 168.161 L 204.075 189.606 C 202.976 190.889 197.512 195.548 192.234 200.387 C 185.636 206.434 192.783 188.291 189.484 184.992 Z"
        fill="white"
        stroke="white"
        strokeWidth="1.09962"
      />
      <path
        d="M 97.224 132.158 L 162.11 244.543 L 32.339 244.543 L 97.224 132.158 Z"
        fill="#CCC9C9"
      />
      <path
        d="M 93.38 157.723 C 89.517 153.86 76.445 168.429 77.249 166.819 L 97.403 133.088 L 114.735 164.476 C 113.126 166.354 105.128 173.173 97.403 180.255 C 87.747 189.107 98.208 162.551 93.38 157.723 Z"
        fill="white"
        stroke="white"
        strokeWidth="1.60944"
      />
    </svg>
  )
}
