import { ChangeEventHandler, ComponentProps, FC, forwardRef, useEffect, useState } from 'react'
import { Combobox } from '@headlessui/react'
import { XCircleIcon } from '@heroicons/react/20/solid'
import { MapIcon } from '@heroicons/react/24/outline'
import { MembersDiscoveryLocation } from 'api/dto'
import { cn } from 'ui/lib'
import { useLocationsSearch } from './useLocationsSearch'

export const DEFAULT_DISTANCE = 30

type CityLocation = Pick<MembersDiscoveryLocation, 'city' | 'region' | 'country'>

export interface CityLocationFilterProps extends ComponentProps<'input'> {
  selectedLocation?: CityLocation
  onLocationChange: (location?: CityLocation) => void
}

export const EMPTY_LOCATION: CityLocation = {
  city: undefined,
  region: undefined,
  country: undefined,
}

export const CityLocationSelect: FC<CityLocationFilterProps> = forwardRef(
  ({ selectedLocation, onLocationChange }, ref) => {
    const { onQueryChangeDebouncedHandler, locations } = useLocationsSearch()

    const [currentLocation, setCurrentLocation] = useState<CityLocation>(EMPTY_LOCATION)

    useEffect(() => {
      setCurrentLocation(selectedLocation || EMPTY_LOCATION)
    }, [selectedLocation])

    const onLocationChangeHandler = (location: CityLocation) => {
      if (!location) {
        onLocationChange(EMPTY_LOCATION)
      } else {
        onLocationChange(location)
      }
      onQueryChangeDebouncedHandler('')
      setCurrentLocation(location)
    }

    const onComboboxQueryChangeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
      onQueryChangeDebouncedHandler(event.target.value)
    }

    const onClearHandler = () => {
      onLocationChange(EMPTY_LOCATION)
      onQueryChangeDebouncedHandler('')
    }

    return (
      <div className="flex w-full flex-col gap-4">
        <Combobox
          as="div"
          className="flex flex-col gap-4"
          value={currentLocation || null}
          onChange={onLocationChangeHandler}
          nullable
        >
          <div
            className={cn(
              'flex flex-nowrap overflow-hidden',
              'border-taupe-600 rounded-lg border',
              'hover:border-taupe-700', // hover
              'focus-within:!border-navy-500', // focus
            )}
          >
            <Combobox.Input
              ref={ref}
              className={cn(
                'w-full border-0 px-0 pl-3 focus:ring-0',
                !selectedLocation && 'pr-3',
                'placeholder: text-deep-teal-300',
                'text-deep-teal-800 text-sm leading-5',
                'focus-visible:border-0 focus-visible:outline-0',
              )}
              displayValue={getGeolocationDisplayName}
              onChange={onComboboxQueryChangeHandler}
            />

            {!isEmpty(selectedLocation) && (
              <div
                className="flex cursor-pointer items-center justify-center px-3"
                data-testid="clear-location"
                onClick={onClearHandler}
              >
                <XCircleIcon className="text-deep-teal-300 h-5 w-5" />
              </div>
            )}
          </div>

          {!!locations.length && (
            <Combobox.Options className="flex flex-col gap-4" static>
              {locations
                .filter((location) => !isSameLocations(location, selectedLocation))
                .map((location) => (
                  <Combobox.Option
                    key={`${location.lng},${location.lat},${location.city},${location.region},${location.country}`}
                    value={location}
                  >
                    <LocationOption
                      displayName={getGeolocationDisplayName(location)}
                      icon={MapIcon}
                      clickable
                    />
                  </Combobox.Option>
                ))}
            </Combobox.Options>
          )}
        </Combobox>
      </div>
    )
  },
)
CityLocationSelect.displayName = 'DiscoveryLocationFilter'

interface LocationOptionProps extends ComponentProps<'div'> {
  icon: FC<ComponentProps<'svg'>>
  displayName: string
  clickable?: boolean
}

const LocationOption: FC<LocationOptionProps> = ({
  className,
  icon: Icon,
  displayName,
  clickable,
  ...props
}) => {
  return (
    <div
      className={cn(
        'flex-no-wrap flex items-center justify-center gap-3',
        clickable && 'cursor-pointer',
        className,
      )}
      {...props}
    >
      <Icon className="text-deep-teal-300 h-5 w-5" />
      <span className="test-sm text-deep-teal-800 w-full leading-5">{displayName}</span>
    </div>
  )
}

// Should not compare coordinates directly
// There are little error on coords calculation every time user do it
const isSameLocations = (a?: CityLocation, b?: CityLocation) =>
  a !== undefined &&
  b !== undefined &&
  a.city === b.city &&
  a.region === b.region &&
  a.country === b.country

const isEmpty = (location?: CityLocation) =>
  !location || (!location.city && !location.region && !location.country)

export const getGeolocationDisplayName = (location?: CityLocation) => {
  if (!location) {
    return ''
  } else {
    return [location.country, location.region, location.city].filter((value) => !!value).join(', ')
  }
}
