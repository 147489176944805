const numberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
})

export function formatNumber(value: number): string {
  return numberFormatter.format(value)
}

export function formatDecimalNumber(value: number): string | number {
  if (!isFinite(value)) {
    return value
  }

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)
}

export function formatPercent(value: number, minimumFractionDigits = 2): string | number {
  if (!isFinite(value)) {
    return value
  }

  const percentFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    signDisplay: 'never',
    minimumFractionDigits,
    maximumFractionDigits: 2,
  })

  return value < 0 ? `(${percentFormatter.format(value)})` : percentFormatter.format(value)
}

const integerPercentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  signDisplay: 'never',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export function formatIntegerPercent(value: number): string {
  return value < 0
    ? `(${integerPercentFormatter.format(value)})`
    : integerPercentFormatter.format(value)
}
