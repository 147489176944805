import { FC, HTMLAttributes } from 'react'
import { Link, useRouter } from 'ui/components/navigation'
import { cn } from 'ui/lib'

export const NavTabs: FC<HTMLAttributes<HTMLDivElement>> = ({ className, children }) => {
  return (
    <nav className={cn(className, '-mb-px flex border-b border-gray-200')} aria-label="Tabs">
      {children}
    </nav>
  )
}
/**
 * Navigation Tab function component
 * @param title - title of the tab
 * @param path - full relative path to the tab content page (from root)
 * @constructor
 */
export const NavTab: FC<{
  className?: string
  title: string
  path: string
}> = ({ className, title, path }) => {
  const router = useRouter()
  const selected = router.pathname === path
  return (
    <Link
      href={path}
      routerDirection="root"
      className={cn(
        'text-accent-800 cursor-pointer whitespace-nowrap border-b-2 border-transparent px-0 pb-4 text-center text-lg font-normal',
        selected ? 'border-accent-500 font-semibold' : 'hover:border-accent-500',
        className,
      )}
      role="link"
      aria-current={selected ? 'page' : undefined}
    >
      {title}
    </Link>
  )
}
